/* styles/ResetPassword.css */

.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  /* Full viewport height */
  background-color: #f0f4ff;
  /* Light blue background */
}

.auth-container {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  /* Maximum width */
}

.auth-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.auth-form input {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.auth-form input:focus {
  border-color: #007bb5;
  /* Blue border on focus */
  outline: none;
}

.auth-btn {
  background-color: #95a5a6;
  /* Grey color */
  color: white;
  font-weight: bold;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-btn:hover {
  background-color: #7f8c8d;
  /* Darker grey on hover */
}

.toggle-link {
  cursor: pointer;
  color: #007bb5;
  /* Light blue for toggle link */
  text-align: center;
  margin-top: 10px;
}