/* User details container */
.user-details {
  background: linear-gradient(135deg, #084447 0%, #111b2e 100%);
  /* Gradient background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  color: #ffffff;
  /* White text for better contrast */
}

/* Title section with name */
.user-details h2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
  text-transform: uppercase;
}

/* Website URL */
.user-details .website-url {
  font-size: 20px;
  color: #eeeeee;
  margin-bottom: 20px;
}

/* Smaller details (phone, plan, expiry) */
.phone,
.plan,
.expiry {
  font-size: 14px;
  color: #cccccc;
  margin: 5px 0;
}

/* Details row for phone, expiry, and plan */
.details-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.detail-item {
  flex: 1;
  background: linear-gradient(135deg, #23282a 0%, #042e36 100%);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.detail-item h4 {
  font-size: 16px;
  color: #00adb5;
  margin-bottom: 10px;
}

.detail-item p {
  font-size: 14px;
  color: #eeeeee;
  margin: 0;
}

/* Mobile-specific adjustments */
/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .user-details {
    padding: 10px;
    /* Reduced padding for mobile */
  }

  .user-details h2 {
    font-size: 18px;
    /* Smaller font for mobile */
    text-align: center;
    /* Center the name */
  }

  .website-url {
    display: none;
    /* Hide website URL on mobile */
  }



  /* Details (phone, plan, expiry) side by side */
  .phone,
  .plan,
  .expiry {
    font-size: 10px;
    /* Smaller font size for details */
    margin: 2px 10px;
    /* Reduced margin for compactness */
  }

  .phone {
    padding-left: 10px;
    /* Add padding to the left of the phone number */
  }

  .details-row {
    display: flex;
    /* Use flex layout */
    justify-content: space-between;
    /* Space out items */
    gap: 10px;
    /* Space between items */
  }

  .detail-item {
    background: none;
    /* No background for detail items */
    padding: 8px;
    /* Smaller padding */
    font-size: 10px;
    /* Smaller font size */
    margin-bottom: 5px;
    /* Smaller margin between items */
    box-shadow: none;
    /* No shadow for detail items */
    text-align: center;
    /* Center text inside detail items */
    flex: 1;
    /* Allow items to take equal width */
  }

  .detail-item h4 {
    font-size: 12px;
    /* Smaller heading font size */
    margin-bottom: 5px;
  }

  .detail-item p {
    font-size: 8px;
    /* Very small text for content */
  }
}




/* General Dashboard Styling */
.user-detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-detail-value {
  margin-left: 5px;
}

.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.user-detail-item:hover .edit-icon {
  opacity: 1;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
  background: #181818;
  color: #e0e0e0;
  font-family: 'Helvetica Neue', sans-serif;
  position: relative;
  overflow-x: hidden;
  margin: 0;
}

/* Sidebar (Always visible on large screens) */
.sidebar {
  width: 250px;
  background: #222831;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  height: 100vh;
}

.logod {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.logod-image {
  width: 150px;
  border-radius: 20px;
}

.menu {
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  transition: background 0.3s;
  cursor: pointer;
  color: #b8b8d4;
  font-size: 1.1rem;
}

.menu-item.active,
.menu-item:hover {
  background: #393e46;
  color: #ffffff;
}

/* Hamburger Menu (only visible on small screens) */
.hamburger-menu {
  display: none;
  justify-content: center;
  align-items: center;
  background: #222831;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: 20px;
  left: 20px;
}

.hamburger-icon {
  font-size: 2rem;
  color: #e0e0e0;
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #1f1f2e;
  position: relative;
  margin-left: 280px;
  padding-left: 30px;
  transition: margin-left 0.3s ease;
}

.clientsite {
  font-size: 2.2rem;
  color: #e0e0e0;
  margin-bottom: 10px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.clientsite:hover {
  color: #4a90e2;
  /* Blue color on hover for interaction */
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
  /* Enhanced shadow on hover */
}

.dashboard-title {
  font-size: 1.8rem;
  color: #f5f5f5;
  margin-bottom: 10px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card {
  background: #2b2b3b;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.large-card {
  grid-column: span 2;
  height: 200px;
}

.card-title {
  font-size: 1.4rem;
  color: #ffffff;
  margin-bottom: 10px;
  opacity: 0.7;
  /* Dimmed card titles */
}

.card-placeholder {
  color: #8888a1;
  font-size: 1rem;
}

.explore-container {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background: #000000;
  /* Solid black background */
  color: #ffffff;
  /* White text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: auto;
  /* Adjust the width to the length of the text */
  min-width: 250px;
}

.explore-message {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 1rem;
}

.explore-button {
  background: #4a90e2;
  border: none;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.explore-button:hover {
  background: #357abf;
}

/* Mobile and Small Screen Responsiveness */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  /* Sidebar is completely hidden by default */
  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background: #222831;
    z-index: 999;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  /* When the sidebar is open, it slides in */
  .sidebar.open {
    display: block;
    transform: translateX(0);
  }

  /* Hamburger menu is always visible on mobile */
  .hamburger-menu {
    display: flex;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }

  /* Adjust main content padding for mobile */
  .main-content {
    padding: 15px;
    margin-left: 0;
  }

  .logod-image {
    padding-left: 60px;
  }

  .clientsite {
    padding-left: 50px;
  }
}