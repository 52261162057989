.pricing-section {
  padding: 60px 20px;
  background: linear-gradient(to right, #003366, #00509e); /* Gradient background */
  color: #ffffff; /* White text for contrast */
  text-align: center;
}

.pricing-section h2 {
  font-size: 3em;
  color: #ffffff; /* White color for heading */
  margin-bottom: 40px;
  font-family: 'Roboto', sans-serif;
}

.pricing-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between columns */
  flex-wrap: wrap;
}

.pricing-card {
  background: #ffffff; /* White background for cards */
  color: #003366; /* Navy text color */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 30px;
  max-width: 280px;
  flex: 1;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid #003366; /* Navy border for card */
}

.pricing-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.pricing-card h3 {
  font-size: 2.2em;
  color: #003366; /* Navy color for card headings */
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700; /* Bold headings */
}

.pricing-card .price {
  font-size: 2.8em;
  color: #00acc1; /* Accent color for price */
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700; /* Bold price */
}

.pricing-card .features {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.pricing-card .features li {
  font-size: 1.1em;
  color: #333;
  margin-bottom: 10px;
}

.cta-button {
  background: linear-gradient(to right, #003366, #004080); /* Gradient button */
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 12px 20px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background: linear-gradient(to right, #004080, #003366); /* Darker gradient on hover */
  transform: scale(1.05); /* Slightly enlarge button */
}

.explore-pricing {
  margin-top: 40px;
  font-family: 'Open Sans', sans-serif;
}

.explore-pricing p {
  font-size: 1.4em;
  color: #ffffff; /* White color for text */
}

.explore-pricing a {
  color: #00acc1; /* Accent color for links */
  text-decoration: underline;
  font-weight: 600; /* Bold links */
}

.explore-pricing a:hover {
  color: #00796b; /* Darker color on hover */
}
