/* Request a Call Section */
.request-call-section {
  background: #e0f7fa; /* Light background matching the website theme */
  color: #004d40; /* Dark teal text color */
  text-align: center;
  padding: 60px 20px; /* Increased padding for prominence */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 20px auto; /* Centered with margin for spacing */
  max-width: 1200px; /* Maximum width for larger screens */
}

.request-call-section h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif; /* Modern and clean font */
}

.request-call-section p {
  font-size: 1.2em;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif; /* Complementary font */
}

.request-call-button {
  background: #004d40; /* Dark teal button background */
  color: #ffffff; /* White text for the button */
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.request-call-button:hover {
  background: #00332d; /* Slightly darker teal on hover */
  color: #ffffff; /* White text on hover */
}
