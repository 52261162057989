/* Video and CTA Section */
#video-cta-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  background-color: #fff;
  margin-top: 20px; /* Adjust the margin-top to your preference */
}

.video-container {
  flex: 1;
  margin-right: 20px;
  
}

.video-container video {
  width: 80%;
  border-radius: 8px;
  margin-left: 50px;
}

.cta-container {
  flex: 1;
}

.cta-container h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.cta-container p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #f39c12;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.2rem;
}

.cta-button:hover {
  background-color: #e67e22;
}

/* Responsive Styles */
@media (max-width: 768px) {
  #video-cta-section {
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    margin-right: 0;
    margin-bottom: 20px;

  }

  .cta-container {
    text-align: center;
    
  }
}
