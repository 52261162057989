/* Hero Section Styles */
#hero-section video {
  width: 100%;
  height: 100%;
}

#hero-section {
  margin-top: 40px;
}



@media screen and (max-width: 480px) {
  .hero-section video {
    height: auto;
    /* Maintain aspect ratio */
    width: 100%;
    /* Responsive width */
  }

}