/* Services Overview Container */
#services-overview {
  padding: 50px 20px;
  background: transparent;
  text-align: center;
}

#services-overview h2 {
  font-size: 2.5em;
  color: #004d40;
  margin-bottom: 40px;
  font-weight: 700;
}

/* Services Container with a Clean Grid Layout */
.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns layout */
  grid-gap: 20px; /* Gaps between items */
  width: 100%;
  max-width: 1200px;
}

/* Service Items Styling */
.service-item {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item h3 {
  font-size: 1.5em;
  color: #00796b;
  margin-bottom: 15px;
  font-weight: 600;
}

.service-item p {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}

.checkmark {
  vertical-align: middle;
  margin-right: 8px;
}

/* Services Video Styling */
.services-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-video video {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .services-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .services-list {
    grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}
