/* Base styles for large screens (desktop) */
/* Include your desktop CSS here */

/* General Styles */
.services-video {
  width: 100%; /* Full width for the video container */
  display: flex; /* Use flexbox to center */
  justify-content: center; /* Center the video */
  align-items: center; /* Align items vertically */
}

.services-video video {
  width: 100%; /* Make the video responsive */
  height: auto; /* Maintain aspect ratio */
  max-width: 480px; /* Maximum width for small screens */
}

/* Mobile Devices (Portrait and Landscape) */
@media (max-width: 480px) {

  .introduction-section {
    flex-direction: column;
    padding: 10px 10px;
    
}

  .introduction-text h2 {
      line-height: 1.4;
      font-size: 2em;
  }

  .introduction-text {
      padding: 0px;
  }

  .highlight {
      font-family: "Lora", serif; /* Elegant font for emphasis */
      font-size: 1.8em; /* Slightly larger size for emphasis */
      color: #00796b; /* Stylish color */
      background: linear-gradient(to right, rgb(142, 142, 244), rgb(28, 28, 60)); /* Subtle background color */
      padding: 5px; /* Padding for separation from the background */
      border-radius: 5px; /* Rounded corners for a softer look */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
      margin-right: 0px;
  }

  .subheadline {
      margin-left: 1px;
      color: #00796b; /* Stylish color */
      background: linear-gradient(to right, #f3f5f5, #5cc1b0);
  }

  .introduction-animation {
      margin: 10px;
  }

  .request-call-section {
      margin: 0;
  }

  .pricing-section h2 {
      display: block;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
      unicode-bidi: isolate;
      margin-top: -30px;
      margin-bottom: 0;
      padding: 0;
  }

  .free-section {
      margin: 5px;
      box-shadow: inset;
  }

  .header img {
      height: auto; /* Adjust image size for mobile */
      width: 100px;
  }

  .welcome, .discover, .pricing, .call {
      font-size: 14px; /* Adjust font size for better proportion */
  }

  .call {
      background-color: transparent; /* Distinct color for call to action */
      padding: 0; /* Increased padding for a button-like appearance */
      border-radius: 0; /* Larger rounded corners for a smoother button */
      color: #ffffff; /* White text color */
      font-weight: bold; /* Emphasize call to action */
      box-shadow: none; /* Subtle shadow for depth */
      transition: background-color 0.3s ease, transform 0.3s ease;
      font-size: 13px;
  }

  .hero-section video {
      height: auto; /* Maintain aspect ratio */
      width: 100%; /* Responsive width */
  }



  .introduction-text, .introduction-animation {
      max-width: 100%;
  }

  .highlight {
      font-size: 1.5em;
      margin-right: 0;
  }

  .subheadline {
      margin-left: 0;
  }

  .with-vyrex-section {
      padding: 20px 10px;
  }

  .question {
      font-size: 1em;
      padding: 10px 15px;
  }

  .services-list {
      grid-template-columns: 1fr;
  }

  .services-video video {
      width: 100%; /* Make the video responsive */
      height: auto; /* Maintain aspect ratio */
      max-width: 100%; /* Allow full width */
  }

  .free-section {
      padding: 20px 10px;
  }

  .benefit-item {
      flex-direction: column;
      padding: 10px;
  }

  .cta-button {
      font-size: 1em;
      padding: 10px 20px;
  }

  .pricing-container {
      flex-direction: column;
  }

  .pricing-card {
      max-width: 100%;
      margin-bottom: 0;
      padding: 10px;
  }

  .request-call-section {
      padding: 40px 15px;
  }
}

/* Tablet Devices (Portrait and Landscape) */
@media (min-width: 481px) and (max-width: 768px) {
  .header {
      gap: 50px;
  }

  .hero-section video {
      height: auto;
  }

  .introduction-section {
      padding: 40px 20px;
  }

  .introduction-text, .introduction-animation {
      max-width: 100%;
  }

  .highlight {
      font-size: 1.8em;
  }

  .subheadline {
      margin-left: 50px;
  }

  .with-vyrex-section {
      padding: 30px 20px;
  }

  .question {
      font-size: 1.1em;
      padding: 12px 20px;
  }

  .services-list {
      grid-template-columns: repeat(2, 1fr);
  }

  .services-video video {
      width: 100%; /* Make video responsive */
  }

  .free-section {
      padding: 30px 20px;
  }

  .benefit-item {
      flex-direction: row;
      padding: 15px;
  }

  .cta-button {
      font-size: 1.1em;
      padding: 12px 25px;
  }

  .pricing-container {
      flex-direction: row;
      flex-wrap: wrap;
  }

  .pricing-card {
      max-width: 100%;
      margin-bottom: 20px;
  }

  .request-call-section {
      padding: 50px 20px;
  }
}
