  .main{
    background-color:transparent;
  }
  
  
  /* Testimonials Section */
  #testimonials-section {
    position: relative;
    padding: 40px 20px;
    background: linear-gradient(to right, #d5dcdb, #0097a7);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    border-radius: 8px; 
    overflow: hidden; /* Ensures content doesn't overflow */
    margin-top: 60px;
  }

    #testimonials-section h2 {
      text-align: center;
      color: #032548;
      background: linear-gradient(to right, #181818, #0097a7);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 0 20px 0;
      font-size: 2.0rem; 
    }

  .testimonials-video {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    
  }

  .testimonials-video .testimonial-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
  }

  .testimonial {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 300px;
  }

  .testimonial p {
    font-size: 16px;
    color: #333;
  }

  .client {
    font-style: italic;
    color: #00796b;
  }

  .video-container {
    flex-shrink: 0;
  }


  /* Why Choose Vyrex Section */
  #why-choose-vyrex {
    margin: 20px;
    padding: 20px;
    background: linear-gradient(to right, rgb(182, 186, 187), rgb(108, 147, 208));
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  #why-choose-vyrex h2 {
    text-align: center;      
    color: #032548;
    background: linear-gradient(to right, #101111, #066974);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgb(131, 131, 235);
    -webkit-text-fill-color: transparent;
    font-size: 2.0rem; 
  }

  #why-choose-vyrex ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: ;
  }

  #why-choose-vyrex ul li {
    margin-bottom: 10px;
  }

  /* FAQ Section */
  #faq-section {
    margin: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  #faq-section h2 {
    text-align: center;
    color: #032548;
    background: linear-gradient(to right, #00796b, #0097a7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgb(18, 18, 102);
    -webkit-text-fill-color: transparent;
    font-size: 2.0rem; 
  }

  .faq-item {
    margin-bottom: 15px;
    cursor: pointer;
    padding: 15px;
    background: #f0f8ff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dcdcdc;
    transition: background-color 0.3s ease;
  }

  .faq-item:hover {
    background-color: #e0f7fa;
  }

  .faq-answer {
    display: none;
    padding: 10px;
    background: #fafafa;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid #dcdcdc;
  }

  .faq-item .arrow {
    font-size: 18px;
    transition: transform 0.3s ease;
  }

  .faq-item.active .arrow {
    transform: rotate(180deg);
  }

  .faq-item.active .faq-answer {
    display: block;
  }

  /* Mobile Styles */
  /* Mobile Styles */
  @media (max-width: 768px) {

    
    #testimonials-section {
      padding: 12px; /* Add padding for better spacing */
      overflow: hidden; /* Prevent horizontal scrolling */
      
    }
    #faq-section{
      margin: 0px;
      padding: 10px;
    }
    #testimonials-section h2{
      margin-bottom: 0px;
      -webkit-text-fill-color: transparent;
      font-size: 2.0rem; 
      
    }
    #why-choose-vyrex{
      margin: 5px;
    }

    .testimonials-video {
      display: block; /* Stack testimonials vertically */
      align-items: center;
    }

    .testimonial {
      width: calc(100% - 20px); /* Full width with padding adjustment */
      margin: 10px 0; /* Margin for vertical spacing */
      font-size: 14px; /* Adjust font size for readability */
      background: #ffffff; /* White background for clean look */
      padding: 15px; /* Add padding inside testimonial block */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
      border-radius: 8px; /* Rounded corners */
    }

    .video-container {
      display: none; /* Hide video on mobile */
    }


  }


  #faq-section {
    margin: 20px;
  }
  
  .faq-item {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .faq-item.active {
    background-color: #f0f0f0;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  
  .faq-answer {
    margin-top: 10px;
  }
  
  .arrow {
    font-size: 1.2em;
  }
  