.login-icon {
  position: fixed;
  top: 80px;
  /* Below the header */
  right: 20px;
  /* Fix to the right side */
  background-color: #4CAF50;
  /* A modern green shade */
  color: white;
  /* White text for contrast */
  padding: 10px 20px;
  /* Padding for a button-like appearance */
  border-radius: 30px;
  /* More subtle pill-shaped button */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /* Adjusted font size */
  font-weight: 600;
  /* Slightly bold for prominence */
  text-transform: uppercase;
  /* Uppercase text for modern feel */
  cursor: pointer;
  /* Pointer on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  /* Smooth transitions */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */

  z-index: 10000;
}

.login-icon i {
  margin-right: 8px;
  /* Space between icon and text */
  font-size: 18px;
  /* Adjust icon size */
}

.login-icon:hover {
  background-color: #388E3C;
  /* Darken the background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  /* Slightly stronger shadow */
  transform: translateY(-3px);
  /* Lift the button slightly on hover */
}

.login-icon:active {
  background-color: #2E7D32;
  /* Even darker shade on click */
  transform: translateY(1px);
  /* Give a slight push-in effect on click */
}

.login-icon span {
  font-size: 14px;
  /* Smaller text size for a sleeker look */
  letter-spacing: 1px;
  /* Slight spacing for readability */
}


.dashboard-icon {
  position: fixed;
  top: 80px;
  /* Same vertical position as login icon */
  right: 20px;
  /* Fix to the right side, same as login icon */
  background-color: rgb(135, 206, 250);
  /* A modern blue shade for professionalism */
  color: rgb(235, 218, 218);
  /* White text for contrast */
  padding: 10px 20px;
  /* Padding for a button-like appearance */
  border-radius: 30px;
  /* Subtle pill-shaped button */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /* Consistent font size */
  font-weight: 600;
  /* Bold for prominence */
  text-transform: uppercase;
  /* Uppercase text for a modern feel */
  cursor: pointer;
  /* Pointer on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  /* Smooth transitions */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */

  z-index: 10000;
}

.dashboard-icon i {
  margin-right: 8px;
  /* Space between icon and text */
  font-size: 18px;
  /* Adjust icon size */
}

.dashboard-icon:hover {
  background-color: rgb(81, 177, 237);
  /* Darken the background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  /* Slightly stronger shadow */
  transform: translateY(-3px);
  /* Lift the button slightly on hover */
}

.dashboard-icon:active {
  background-color: rgb(3, 70, 112);
  /* Even darker shade on click */
  transform: translateY(1px);
  /* Give a slight push-in effect on click */
}

.dashboard-icon span {
  font-size: 14px;
  /* Smaller text size for a sleeker look */
  letter-spacing: 1px;
  /* Slight spacing for readability */
}





.call {
  margin-right: 35px;
}

/* Header Styles */
.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  /* Align items for a balanced layout */
  height: 70px;
  /* Slightly increased height for better balance */
  background: linear-gradient(to right, #46787d, #0097a7);
  /* Gradient background for a modern look */
  align-items: center;
  border-radius: 10px;
  /* Slightly larger border radius for a softer look */
  width: 100%;
  top: 0;
  left: 0;
  gap: 30px;
  /* Increased gap for better spacing */
  padding: 0 20px;
  /* Added padding for better content placement */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Slightly increased shadow for more depth */
  z-index: 1000;
  /* Keeps the header on top */
}

.header img {
  height: 70px;
  width: 140px;
  /* Adjust height to maintain aspect ratio and fit better */
  border-radius: 20px;
  /* Circular logo for a modern touch */
  transition: transform 0.3s ease;
  /* Add hover effect on logo */
}

.header img:hover {
  transform: scale(1.1);
  /* Slight zoom on hover for visual interest */
}

.welcome,
.discover,
.pricing,
.call {
  font-size: 18px;
  /* Adjust font size for better proportion */
  font-weight: 600;
  /* Lighter weight for readability */
  color: #ffffff;
  /* White text color for contrast */
  text-transform: uppercase;
  /* Uppercase for a clean, modern look */
  letter-spacing: 1px;
  /* Slight letter spacing for readability */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  /* Smooth transition for hover effects */
}

.welcome:hover,
.discover:hover,
.pricing:hover,
.call:hover {
  color: #e0f7fa;
  /* Light color on hover for a nice effect */
  transform: scale(1.1);
  /* Slightly enlarge the text on hover */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    /* Stack items vertically on small screens */
    height: auto;
    /* Auto height to accommodate content */
    padding: 2px;
    /* Reduced padding for smaller screens */
    gap: 15px;
    /* Reduced gap between elements for smaller screen widths */
  }

  .header img {
    width: 100px;
    height: 50px;
    border-radius: 15px;
    /* Smaller logo for mobile */
  }

  .welcome,
  .discover,
  .pricing,
  .call {
    font-size: 16px;
    /* Reduced font size for better readability on mobile */
    letter-spacing: normal;
    /* Normalize spacing on mobile */
  }

  .call {
    padding: 8px 12px;
    /* Adjusted padding for mobile buttons */
    margin-right: 8px;
    /* Margin adjustment for mobile */
  }
}

/* Styles for smaller devices (if necessary) */
@media (max-width: 480px) {
  .call {
    margin-right: 8px;
    /* Override for even smaller screens */
  }

  .welcome {
    display: none;
  }
}