#free-section {
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 50px auto;
  max-width: 800px;
  position: relative;
  overflow: hidden;
  border: 2px solid #004d40; /* Adding border with navy color */
}

#free-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 77, 64, 0.1); /* Light navy overlay */
  z-index: -1;
}

.free-header {
  margin-bottom: 30px;
}

.free-header h2 {
  font-size: 2.4em;
  color: #004d40;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.free-header p {
  font-size: 1.2em;
  color: #333;
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
}

.free-benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.benefit-item {
  display: flex;
  align-items: center;
  background: #f0f8f8;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.benefit-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.benefit-item span {
  font-size: 1.2em;
  color: #004d40;
  font-family: "Roboto", sans-serif;
}

.cta-button {
  background-color: #004d40;
  color: #fff;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 12px;
  font-size: 1.2em;
  font-weight: 600;
  display: inline-block;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: #003d33;
  transform: scale(1.05);
}
