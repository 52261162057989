/* With Vyrex Section */
#with-vyrex-section {
  text-align: center;
  padding: 40px 20px; /* Added padding for overall spacing */
  background-color: transparent; /* Light background color for contrast */
}

.with-vyrex-questions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #b9e8f0; /* Light background color */
  padding: 20px; /* Padding around questions */
  border-radius: 15px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 30px; /* Space below the questions section */
}

.question {
  font-family: "Roboto", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: #004d40; /* Dark teal color for text */
  background-color: #ffffff; /* White background for questions */
  padding: 15px 25px; /* Padding inside each question */
  border-radius: 25px; /* Rounded corners for each question */
  margin: 10px; /* Margin between questions */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

.question:hover {
  background-color: #e0f2f1; /* Light background color on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.with-vyrex-tagline {
  background: linear-gradient(to right, rgb(63, 63, 160), rgb(181, 181, 193)); /* Gradient background */
  color: #ffffff; /* White text color */
  font-family: "Lora", serif;
  font-size: 1.6em; /* Slightly larger font size */
  font-weight: 700;
  border-radius: 30px; /* Rounded corners */
  padding: 20px; /* Increased padding for emphasis */
  margin: 0 auto; /* Center the tagline */
  max-width: 90%; /* Limits width to avoid stretching */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
